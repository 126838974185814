import { camelCase } from 'lodash-es';

export function useFormieField(fieldProp, namespaceProp, namespaceSuffixProp, subfieldsRef = null) {
    const field = toRef(fieldProp);
    const namespace = toRef(namespaceProp);
    const namespaceSuffix = toRef(namespaceSuffixProp);

    const getIdValue = (handles = []) => {
        const parts = [...namespace.value, field.value.handle, ...handles, ...namespaceSuffix.value];
        return getFormieId(parts);
    };

    const getNameValue = (handles = []) => {
        const parts = [...namespace.value, field.value.handle, ...handles, ...namespaceSuffix.value];
        return getFormieName(parts);
    };

    const attrs = (fieldParam = null, handles = []) => {
        const currentField = fieldParam || field.value;

        return {
            id: getIdValue(handles),
            name: getNameValue(handles),
            required: currentField.required,
            placeholder: currentField.placeholder ?? ' ',
            value: currentField.defaultValue,
            ...formieAttributesTable(currentField.inputAttributes),
        };
    };
    const getSubFields = computed(() => {
        const enabledSubFields = [];

        if (subfieldsRef && subfieldsRef?.value) {
            subfieldsRef.value.forEach((subFieldsGroup, groupIndex) => {
                subFieldsGroup.forEach((subField) => {
                    if (field.value[`${subField}Enabled`]) {
                        const subFieldOptions = {};

                        Object.keys(field.value).forEach((key) => {
                            if (key.startsWith(subField)) {
                                const newKey = camelCase(key.replace(subField, ''));
                                subFieldOptions[newKey] = field.value[key];
                            }
                        });

                        if (!enabledSubFields[groupIndex]) {
                            enabledSubFields[groupIndex] = {};
                        }

                        enabledSubFields[groupIndex][subField] = subFieldOptions;
                    }
                });
            });
        }

        return enabledSubFields;
    });

    return {
        attrs,
        getId: getIdValue,
        getName: getNameValue,
        subFields: subfieldsRef?.value,
        enabledSubFields: getSubFields,
    };
}
